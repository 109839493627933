import Cookies from "js-cookie";
let memoizeSupported = undefined;
/**
 * Check if the current environment supports cookies.
 *
 * @see https://git.io/JcXiP
 * @returns
 */

function supportsCookies(cookieName = "test") {
  if (typeof memoizeSupported === "boolean") {
    return memoizeSupported;
  }

  try {
    const opts = {
      sameSite: "Lax"
    };
    Cookies.set(cookieName, "1", opts);
    const result = document.cookie.indexOf("".concat(cookieName, "=")) !== -1;
    Cookies.remove(cookieName, opts);
    memoizeSupported = result;
    return result;
  } catch (e) {
    return false;
  }
}

export { supportsCookies };
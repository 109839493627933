import isbot from "isbot";
/**
 * Is the current browser a spider / crawler?
 */

function isCrawler() {
  const {
    userAgent
  } = navigator;

  if (userAgent) {
    // Lighthouse / Google PageSpeed isn't currently a bot (https://git.io/J3r5z)
    if (/chrome-lighthouse/i.test(userAgent)) {
      return false;
    }

    return isbot(userAgent);
  }

  return false;
}

export { isCrawler };
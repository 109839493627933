import { consent, consentAll, consentSync, unblock } from ".";

/**
 * Create the API functions and make them available through `window.consentApi`.
 *
 * There is also a [stub](https://shorturl.at/bcgyz) implementation:
 *
 * ```js
 * ((a,b)=>{a[b]||(a[b]={},["consentSync"].forEach(c=>a[b][c]=()=>({cookie:null,consentGiven:!1,cookieOptIn:!0})),["consent","consentAll","unblock"].forEach(c=>a[b][c]=(...d)=>new Promise(e=>a.addEventListener(b,()=>{a[b][c](...d).then(e)},{once:!0}))))})(window,"consentApi");
 * ```
 */
function createApi(manager, blocker) {
  const api = {
    consent: (...args) => consent(manager, ...args),
    consentAll: (...args) => consentAll(manager, ...args),
    consentSync: (...args) => consentSync(manager, ...args),
    unblock: (...args) => unblock(blocker, ...args)
  };
  window.consentApi = api; // Dispatch event so e.g. stubs can react to it and forward functions

  window.dispatchEvent(new CustomEvent("consentApi"));
  return api;
}

export { createApi };
import { useBannerOrBlocker, useLegalLinks } from "..";

function joinWithAndSeparator(arr, andSeparator) {
  if (arr.length > 1) {
    arr.splice(arr.length - 1, 0, "{{andSeparator}}");
  }

  return arr.join(", ").replace(/,\s+{{andSeparator}},\s+/g, andSeparator);
}
/**
 * @see https://gist.github.com/unruthless/413930
 * @see https://stackoverflow.com/a/23630325/5506547
 */


const supWithLineHeightFix = '<sup style="vertical-align:top;line-height:100%;position:initial;">';

function useCommonTeachings({
  disableDataProcessingInUnsafeCountries,
  disableAgeNotice,
  disableListServicesNotice
} = {}) {
  const {
    blocker,
    groups,
    ePrivacyUSA: isEPrivacyUSA,
    ageNotice: isAgeNotice,
    listServicesNotice: isListServicesNotice,
    texts: {
      description,
      ePrivacyUSA,
      ageNoticeBanner,
      listServicesNotice,
      consentForwardingExternalHosts: consentForwardingExternalHostsText
    },
    tcf,
    consentForwardingExternalHosts,
    individualPrivacyOpen,
    individualTexts: {
      description: individualDescription
    },
    essentialGroup,
    i18n: {
      andSeparator,
      tcf: tcfBannerI18n
    },
    keepVariablesInTexts
  } = useBannerOrBlocker();
  const {
    linkPrivacyPolicy
  } = useLegalLinks(); // Description text (combination of configured text and Consent Forwarding)

  let consentForwardingExternalHostsFinalText = "";

  if (consentForwardingExternalHosts && !keepVariablesInTexts) {
    consentForwardingExternalHostsFinalText = consentForwardingExternalHostsText.replace(/{{websites}}/g, consentForwardingExternalHosts.join(", "));
  }

  let useDescription = [blocker ? blocker.description : individualPrivacyOpen ? individualDescription : description, consentForwardingExternalHostsFinalText].filter(Boolean).join(" ");

  if (!keepVariablesInTexts) {
    // Place policy link into the text https://regex101.com/r/ayeFVy/1
    useDescription = useDescription.replace(/{{privacyPolicy}}(.*){{\/privacyPolicy}}/g, linkPrivacyPolicy ? "<a href=\"".concat(linkPrivacyPolicy.url, "\" style=\"text-decoration:underline;color:inherit;\" onmouseenter=\"this.style.textDecoration='none'\" onmouseleave=\"this.style.textDecoration='underline'\" target=\"_blank\">$1</a>") : "$1");
  } // Teaching: Data processing in unsafe countries (formerly USA ePrivacy)


  const servicesProcessingUnsafeCountries = groups.map(({
    items
  }) => items.filter(({
    ePrivacyUSA
  }) => ePrivacyUSA)).flat();
  const tcfVendorsProcessingUnsafeCountries = process.env.IS_TCF && tcf ? Object.values(tcf.gvl.vendors).filter(vendor => {
    var _additionalInformatio;

    return ((_additionalInformatio = vendor.additionalInformation) === null || _additionalInformatio === void 0 ? void 0 : _additionalInformatio.internationalTransfers) || tcf.original.vendorConfigurations[vendor.id].ePrivacyUSA === 1;
  }) : [];
  let useDataProcessingInUnsafeCountries = !disableDataProcessingInUnsafeCountries && (servicesProcessingUnsafeCountries.length > 0 || tcfVendorsProcessingUnsafeCountries.length > 0) && isEPrivacyUSA ? ePrivacyUSA : ""; // Teaching: Age notice

  const useAgeNotice = isAgeNotice && !disableAgeNotice ? ageNoticeBanner : ""; // Teaching: List services notice

  let useListServicesNotice = "";

  if (isListServicesNotice && !disableListServicesNotice) {
    const hasBothPlaceholders = listServicesNotice.indexOf("{{services}}") > -1 && listServicesNotice.indexOf("{{serviceGroups}}") > -1;
    const nonEssentialGroups = groups.filter(({
      slug
    }) => slug !== essentialGroup);
    const flattenSeparator = "~;~";
    const listServicesNames = joinWithAndSeparator(nonEssentialGroups.map(({
      items
    }, groupIdx) => items.map(({
      name,
      ePrivacyUSA
    }) => "".concat(name).concat(supWithLineHeightFix).concat([hasBothPlaceholders ? groupIdx + 1 : "", useDataProcessingInUnsafeCountries && ePrivacyUSA ? "U" : ""].filter(Boolean).join(","), "</sup>")).join(flattenSeparator)).join(flattenSeparator).split(flattenSeparator), andSeparator);

    if (listServicesNames) {
      const listServiceGroupsNames = joinWithAndSeparator(nonEssentialGroups.map(({
        name
      }, groupIdx) => "".concat(name).concat(supWithLineHeightFix).concat(hasBothPlaceholders ? groupIdx + 1 : "", "</sup>")), andSeparator);
      useListServicesNotice = "<span>".concat(listServicesNotice, "</span>").concat(tcf && !individualPrivacyOpen ? " <span>".concat(tcfBannerI18n.listOfServicesAppendix, "</span>") : "");

      if (!keepVariablesInTexts) {
        // Place service list into the text
        useListServicesNotice = useListServicesNotice.replace(/{{services}}/g, listServicesNames) // Place service group list into the text
        .replace(/{{serviceGroups}}/g, listServiceGroupsNames);
      }

      if (useDataProcessingInUnsafeCountries) {
        useDataProcessingInUnsafeCountries += "".concat(supWithLineHeightFix, "U</sup>");
      }
    }
  }

  return {
    description: useDescription,
    teachings: [useDataProcessingInUnsafeCountries, useAgeNotice, useListServicesNotice].filter(Boolean)
  };
}

export { useCommonTeachings };
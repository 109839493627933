function matchingSelector(element, selector) {
  const proto = typeof Element !== "undefined" ? Element.prototype : {};
  const nativeFunction = proto.matches || proto.matchesSelector || proto.webkitMatchesSelector || proto.mozMatchesSelector || proto.msMatchesSelector || proto.oMatchesSelector;

  if (!element || element.nodeType !== 1 || !element.parentElement) {
    return false;
  }

  if (nativeFunction) {
    return nativeFunction.call(element, selector);
  }

  const matchingElements = element.parentElement.querySelectorAll(selector);

  for (const matchIdx in matchingElements) {
    if (matchingElements[matchIdx] === element) {
      return true;
    }
  }

  return false;
}

export { matchingSelector };